import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem, Link,f7, f7ready} from 'framework7-react';
import {FlexBox, FlexItem} from '../components/flex'
import ReactHTMLParser from 'react-html-parser'
import {camelize} from '../js/util'


export default ({tabs,products,page})=>{  
  const [active, setActive] = useState(tabs[0])      
    return(
    <>
     <Popover className={`${page}-menu`}>
        <List>
          {tabs.map(tab=>{
            console.log(tab)
          return(
            <ListItem link="#" style={(active||tabs[0])==tab ? {cursor:"pointer",fontFamily:"Montserat-bold",backgroundColor:"#1275bc",color:"white"} : {cursor:"pointer",fontFamily:"Montserat-bold"}} onClick={()=>{
              console.log('active product tab is , ' ,tab)
              setActive(tab)
            }} popoverClose title={tab} />
          )})}
        </List>
        <br/>
      </Popover>
     {/* mobile view */}
     <div className="md:hidden block"> 
     <div className="m-3">
          <Button 
          small 
          outline 
          round 
          popoverOpen={`.${page}-menu`}
          style={{fontFamily:"Montserat-bold",fontSize:"14px"}}
          >
            {(active||tabs[0])}
            <span style={{marginLeft:"10px"}}>
              <Icon f7="chevron_up" size="15"></Icon>
            </span>
          </Button>
      </div>
        <h2 style={{marginTop:"25px",fontSize:"16px",textAlign:"center",padding:"0 10px",fontWeight:"bolder",fontFamily:"Montserat-bold",paddingBottom:"40px"}} id="header2">
         {products.filter(p=>p.categories.includes((active||tabs[0]))).map(prod=>(
            'image' in prod 
            ? 
            <div style={{margin:"10px 0 25px 0"}}>
              <div className="titleTag" style={{width:"40%",marginLeft:"auto",marginRight:"auto"}}>
                {prod.title}
              </div>
              <img src={prod.image} alt={prod.title} className="w-full"/>
              <div style={{marginTop:"15px",fontSize:"14.5px",textAlign:"justify",fontFamily:"Montserat",padding:"0 5px"}}>
                {ReactHTMLParser(prod.description)}
              </div>
            </div>
            : 
            'unknown content goes here' 
          ))}
        </h2>
      </div>
      
      {/* desktop view */}
      <div className="md:block hidden" style={{marginTop:"20px"}}>
          <FlexBox>
            <FlexItem flex="1">
              {tabs.map(tab=>(
                <div style={(active||tabs[0])==tab ? {cursor:"pointer",fontFamily:"Montserat-bold",backgroundColor:"#1275bc",color:"white",borderRadius:"0 20px 20px 0",padding:"5px",fontSize:"13px",textAlign:"left"} : {cursor:"pointer",fontFamily:"Montserat-bold",fontSize:"13px",padding:"5px",color:"#4f4f4f",textAlign:"left"}}>
                    <a onClick={()=>{
                      console.log('active about tab is , ' ,tab)
                      setActive(tab)
                    }}>
                      {tab}
                    </a>
                </div>
              ))}
            </FlexItem>
            <FlexItem flex="1"></FlexItem>
            <FlexItem flex="4">
              <div  className="titleTag" style={{marginRight:"auto",marginLeft:"auto", fontSize:'35px',width:"55%"}}>{active}</div>
              <h2 style={{marginTop:"25px",fontSize:"16px",textAlign:"center",padding:"0 10px",fontWeight:"bolder",fontFamily:"Montserat-bold",paddingBottom:"40px"}} id="header2">
                {products.filter(p=>p.categories.includes((active||tabs[0]))).map(prod=>(
                  <div style={{margin:"10px 0 35px 0"}}>
                    <div className="md:grid md:grid-cols-5" style={{border:"1px solid #8abee4",borderRadius:"30px",textAlign:"center",alignItems:'center'}}>
                      <div className="md:col-span-2">
                        <img src={prod.image} style={{borderRadius:"30px"}} className="w-full" alt=""/>
                      </div>
                      <div className="md:col-span-3" >
                        <div className="titleTag" style={{width:"30%",marginLeft:"auto",marginRight:"auto",marginTop:"0"}}>
                          {prod.title}
                        </div>
                        <div style={{marginTop:"15px",fontSize:"11px",textAlign:"justify",fontFamily:"Montserat",padding:"0 35px"}} id="header2"> 
                          {ReactHTMLParser(prod.description)}
                        </div>
                      </div>
                    </div>
                    
                  </div>
                ))}
              </h2>
            </FlexItem>
            <FlexItem flex="0.4"></FlexItem>
          </FlexBox>
      </div></>
)}