import React, { useState, useEffect } from 'react';
import { Page, Card, List, ListInput, Button,f7} from 'framework7-react';
import {auth,db} from '../js/firebase'


const AdminLogin = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [email , setEmail] = useState('')
  const [password , setPassword] = useState('')
  const [logo, setLogo] = useState(null)

  useEffect(()=>{
    window.onresize = ()=> setScreenWidth(window.innerWidth)

    console.log('admin useEffect')

    //get global data
    db.collection('CMS').doc('Global').get().then(doc=>{
      console.log('doc',doc)
      if(doc.exists){
          setLogo(doc.data().logo)
      }
    })

  })

 
auth.onAuthStateChanged(user=>{
  console.log('auth state changed',user)
  f7.views.main.router.navigate(user?'/admin-home':'/admin')
})

  const Login = ()=>{
      f7.dialog.preloader('authenticating...')
      auth.signInWithEmailAndPassword(email,password).then(res=>{
          console.log('auth success')
      }).catch(err=>{
          console.error(err)
          f7.dialog.alert('authentication failed')
      }).finally(()=>f7.dialog.close())
  }




  return(
    <div style={{height:"100%",backgroundColor:"white"}}>
      <Page style={{height:"100%"}}>
        <div style={{display:screenWidth>768?"flex":'block',height:"100%",alignItems:'center'}}>
          <div style={{flex:"1",backgroundColor:"#1275bc",height:screenWidth>768?'100%':'150px',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <a href="/"><img src={logo} width="260px" style={{marginLeft:"auto",marginRight:"auto"}} alt=""/></a>
          </div>
          <div style={{flex:"2",backgroundColor:"white",height:"100%",display:'flex',justifyContent:'center',alignItems:screenWidth>768?'center':'flex-start',paddingTop:screenWidth>768?'0':'20%'}}>
            <Card style={{padding:"30px 10px 30px 10px",margin:screenWidth>768?"0 150px":"0 20px",width:'100%'}}>

                <h2 style={{color:"#1275bc",fontFamily:"Montserat-bold",margin:"30px 0 75px 0",textAlign:"center"}}>
                  Log into your Account
                </h2>

                <List inset style={{ margin: "auto" }}>
                  <ListInput
                    outline
                    placeholder="username"
                    required={true}
                    type="text"
                    onChange={(e) => {setEmail(e.target.value) }}
                  >
                  </ListInput>
                  <br/>
                  <ListInput
                    outline
                    placeholder="password"
                    required={true}
                    type="password"
                    onChange={(e) => {setPassword(e.target.value) }}
                  >
                  </ListInput>
                </List>

                <div style={{marginTop:"30px"}} className="md:grid md:grid-cols-7">
                  <div className="md:col-span-2"></div>
                  <div className="md:col-span-3"> 
                    <Button fill round id="smallButton" onClick={Login}>
                      Log In
                    </Button>
                  </div>
                  <div className="md:col-span-2"></div>
                </div>
            </Card>
          </div>
        </div>
{/*         
        <div className="md:grid md:grid-cols-5" style={{height:"100%",textAlign:"center"}}>
          <div className="md:col-span-2" style={{height:"100%",backgroundColor:"#1275bc"}}>
            <img src="static/logoTransparent.png" width="260px" style={{marginLeft:"auto",marginRight:"auto"}} alt=""/>
          </div>
          <div className="md:col-span-3" style={{height:"100%",backgroundColor:"white",padding:"0 20%"}}>
            <div >
              <Card style={{padding:"30px 10px 30px 10px"}}>
                <h2 style={{color:"#1275bc",fontFamily:"Montserat-bold",margin:"30px 0 75px 0"}}>
                  Log into your Account
                </h2>

                <List inset style={{ margin: "auto" }}>
                  <ListInput
                    outline
                    placeholder="username"
                    required={true}
                    type="text"
                    onChange={(e) => {setUsername(e.target.value) }}
                  >
                  </ListInput>
                  <br/>
                  <ListInput
                    outline
                    placeholder="password"
                    required={true}
                    type="password"
                    onChange={(e) => {setPassword(e.target.value) }}
                  >
                  </ListInput>
                </List>

                <div style={{marginTop:"30px"}} className="md:grid md:grid-cols-7">
                  <div className="md:col-span-2"></div>
                  <div className="md:col-span-3"> 
                    <Button fill round id="smallButton" onClick={Login}>
                      Log In
                    </Button>
                  </div>
                  <div className="md:col-span-2"></div>
                </div>
            </Card>
            </div>
          </div>
        </div> */}

      </Page>
    </div>
  )
};

export default AdminLogin;