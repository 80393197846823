import React, { useState, useEffect } from 'react';
import {Block,BlockTitle,Button,Icon,Link,f7, Chip,Row,Col} from 'framework7-react'

import TextBlock from './text'
import RichTextBlock from './richText'
import ImageBlock from './image'
import { camelize } from '../../../js/util';
import {DEFAULT_IMAGE} from '../../../js/schema'

export default ({schema,data= [],onChange}) =>{
  
    const [categoriesTemp,setCategoriesTemp] = useState('')
    const [addNew, setAddNew] = useState(false)
    const [products,setProducts] = useState(null)

    

    return(
        <Block>
            <BlockTitle>{schema.title}</BlockTitle>
            {/* manage products */}
            <Block>
                <BlockTitle>Products</BlockTitle>
               {(products|| data).map((content,idx)=>{
               
                const update = (type,value)=>{
                    console.log(type,value)
                    console.log('content',content)
                    const updated = (products||data).map(c=>{
                        if(c.title==content.title){
                            switch (type) {
                               
                                case 'description':
                                    return{
                                        ...content,
                                        description:value
                                    }
                                case 'categories':
                                    return {
                                       ...content,
                                        categories: content.categories?[...content.categories,value]:[value]
                                    }
                                case 'title':
                                    return {
                                       ...content,
                                        title: value,
                                    }
                                case 'image':
                                    return {
                                       ...content,
                                        image: value,
                                    }
                            
                                default:
                                    return c
                            }
                        }else return c
                    })                
                    setProducts(updated)
                    onChange(updated)
                }
                const removeCategory = (value)=>{
                    console.log('remove category',value)
                    const updated =  (products||data).map(c=>{
                        if(c.title==content.title){
                            return {
                                ...content,
                                categories: content.categories?content.categories.filter(t=>t!=value):[value]
                            }
                        }else return c
                    })                
                    setProducts(updated)
                    onChange(updated)
                }

                const remove = ()=>{
                    f7.dialog.confirm(`remove ${content.title}?`,null,()=>{
                        const updated = (products|| data).filter(c=>c.title!=content.title)      
                        console.log('remove',updated)          
                        setProducts(updated)
                        onChange(updated)
                    })
                }

               return <Block className={`p-3 ${idx%2==0? 'bg-gray-100':'bg-gray-50'}`}>
                     <Link iconSize="15px" className="text-gray-500" onClick={remove} iconF7="multiply_circle_fill" style={{float:'right'}}></Link>
                   <TextBlock schema={{label:'title'}} data={content.title} onChange={val=>update('title',val)} ></TextBlock>
                   <ImageBlock schema={{label:'image'}} data={content.image ||DEFAULT_IMAGE} onChange={val=>update('image',val)}></ImageBlock>
                   <Row style={{alignItems:'center'}}>
                        <Col width="80"><TextBlock inputId="categories" data={categoriesTemp} schema={{label:'categories'}} onChange={val=>{setCategoriesTemp(val)}} ></TextBlock></Col>
                        <Col width="20"><Link className="text-green-500" onClick={()=>{
                            if(categoriesTemp){
                                update('categories',categoriesTemp)
                                setCategoriesTemp('')
                            }
                        }} iconF7="plus_square_fill" ></Link></Col>
                        {content.categories?<Col width="100">{content.categories?<div>{content.categories.map(category=><Chip text={category} deleteable onDelete={()=>{
                           removeCategory(category)
                        }}></Chip>)}</div>:null}</Col>:null}
                    </Row>
                   <RichTextBlock schema={{label:'content'}} data={content.description} onChange={val=>update('description',val)} ></RichTextBlock>
               </Block>})}
               <BlockTitle>
                    {addNew?<New products={products || data}  setProducts={setProducts} onChange={onChange} setAddNew={setAddNew}/>:
                   <Button onClick={()=>setAddNew(true)}>ADD SECTION &nbsp; &nbsp;<Icon f7="plus_square"/></Button>
                    }
               </BlockTitle>
            </Block>
        </Block>
    )
}


const New = ({setAddNew,products,setProducts,onChange})=>{
    const [title,setTitle] = useState('')
    const [image,setImage] = useState(null)
    const [description,setDescription] = useState('')
    const [categoriesTemp,setCategoriesTemp] = useState('')
    const [categories,setCategories] = useState([])


    const add = ()=>{

        if(products.filter(c=>camelize(c.title)==camelize(title)).length)
            f7.dialog.alert('title already exists')
        else{
            const updated = [...products,{title,description,image,categories}]            
            setProducts(updated)
            onChange(updated)
            close()
        }
    }

    const close = ()=>{
        setTitle('')
        setDescription('')
        setCategories([])
        setImage(null)
        setAddNew(false)
    }

    return <Block className={`p-3 bg-blue-100`}>
        <Link className="text-red-500" onClick={close} iconF7="multiply_circle_fill" style={{float:'right'}}></Link>
        <TextBlock schema={{label:'title'}} onChange={val=>setTitle(val)}></TextBlock>
        <ImageBlock schema={{label:'image'}} data={DEFAULT_IMAGE}  onChange={val=>setImage(val)}></ImageBlock>
        <Row style={{alignItems:'center'}}>
            <Col width="80"><TextBlock inputId="categories" data={categoriesTemp} schema={{label:'categories'}} onChange={val=>{setCategoriesTemp(val)}} ></TextBlock></Col>
            <Col width="20"><Link className="text-green-500" onClick={()=>{
                if(categoriesTemp){
                    setCategories([...categories,categoriesTemp])
                    setCategoriesTemp('')
                }
            }} iconF7="plus_square_fill" ></Link></Col>
              {categories?<Col width="100">{categories?<div>{categories.map(category=><Chip text={category} deleteable onDelete={()=>{
                setCategories(categories.filter(t=>t!=category))
            }}></Chip>)}</div>:null}</Col>:null}
        </Row>
        <Block>
            <RichTextBlock schema={{label:'details'}}  onChange={val=>setDescription(val)} ></RichTextBlock>
        </Block>
        <Button onClick={add} >ADD &nbsp; &nbsp;<Icon f7="plus_square"/></Button>
</Block>
}