import React, { useState, useEffect } from 'react';
import {Block,BlockTitle,Button,Icon,Link,f7} from 'framework7-react'

import TextBlock from './text'
import RichTextBlock from './richText'
import { camelize } from '../../../js/util';

export default ({schema,data= [],onChange}) =>{
  
    const [addNew, setAddNew] = useState(false)

    const [contents,setContents] = useState(null)
    
    console.log('conent is now=>',contents)
    console.log('data is now=>',data)

    return(
        <Block>
            <BlockTitle>{schema.label}</BlockTitle>
            <Block>
               {(contents|| data).map((content,idx)=>{
               
                const update = (type,value)=>{
                    console.log('changing',type,'to',value)
                    const updated = (contents|| data).map(c=>{
                        if(c.label==content.label){
                            const returnable = type=='label'?{
                                label: value,
                                body: content.body
                            }:{
                                label: content.label,
                                body: value
                            }
                            
                            console.log('returnable',returnable)
                            return returnable
                        }else return c
                    })                
                    setContents(updated)
                    onChange(updated)
                }


                const remove = ()=>{
                    f7.dialog.confirm(`remove ${content.label}?`,null,()=>{
                        const updated = (contents|| data).filter(c=>c.label!=content.label)      
                        console.log('remove',updated)          
                        setContents(updated)
                        onChange(updated)
                    })
                }

               return <Block className={`p-3 ${idx%2==0? 'bg-gray-100':'bg-gray-50'}`}>
                     <Link iconSize="15px" className="text-gray-500" onClick={remove} iconF7="multiply_circle_fill" style={{float:'right'}}></Link>
                   <TextBlock schema={{label:'title'}} data={content.label} onChange={val=>update('label',val)} ></TextBlock>
                   <RichTextBlock schema={{label:'content'}} data={content.body} onChange={val=>update('body',val)} ></RichTextBlock>
               </Block>})}
               <Block>
                    {addNew?<New contents={contents || data}  setContents={setContents} onChange={onChange} setAddNew={setAddNew}/>:
                   <Button onClick={()=>setAddNew(true)}>ADD SECTION &nbsp; &nbsp;<Icon f7="plus_square"/></Button>
                    }
               </Block>
            </Block>
        </Block>
    )
}


const New = ({setAddNew,contents,setContents,onChange})=>{
    const [label,setLabel] = useState('')
    const [body,setBody] = useState('')

    const add = ()=>{

        if(contents.filter(c=>camelize(c.label)==camelize(label)).length)
            f7.dialog.alert('title already exists')
        else{
            const updated = [...contents,{label,body}]            
            setContents(updated)
            onChange(updated)
            close()
        }
    }

    const close = ()=>{
        setLabel('')
        setBody('')
        setAddNew(false)
    }

    return <Block className={`p-3 bg-blue-100`}>
        <Link className="text-red-500" onClick={close} iconF7="multiply_circle_fill" style={{float:'right'}}></Link>
        <TextBlock schema={{label:'title'}} onChange={val=>setLabel(val)}></TextBlock>
        <RichTextBlock schema={{label:'content'}}  onChange={val=>setBody(val)} ></RichTextBlock>
        <Button onClick={add} >ADD &nbsp; &nbsp;<Icon f7="plus_square"/></Button>
</Block>
}