import React, { useState, useEffect } from 'react';
import {Block,BlockTitle,List,ListInput} from 'framework7-react' 

export default ({schema,data="",onChange,inputId}) =>{
    const [value,setValue] = useState(null)
    return(
        <Block>
            <BlockTitle>{schema.label}</BlockTitle>
            <List>
                <ListInput
                    inputId = {inputId}
                    outline
                    label={schema.label}
                    type="text" 
                    placeholder={`Enter New ${schema.label}`}
                    value={(value||data)}
                    onChange={e=>{
                        setValue(e.target.value)
                        onChange(e.target.value)
                    }}>
                </ListInput>
            </List>
        </Block>
    )
}