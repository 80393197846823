import Global from '../pages/Global.jsx'
import Login from '../pages/admin/login.jsx'
import AdminLogin from '../components/adminLogin'
import Account from '../pages/admin/account.jsx'
import AdminGlobal from '../pages/admin/admin-home.jsx'
import NotFoundPage from '../pages/404.jsx';
import Admin from '../pages/admin/index.jsx'


const options = {
    pushState: true,
    history: true,
    pushStateSeperator: "",
    transition: 'f7-fade',
}

var routes = [{
        path: '/',
        component: Global,
        options
    },{
        path: '/about',
        component: Global,
        options
    },{
        path: '/products',
        component: Global,
        options
    },{
        path: '/services',
        component: Global,
        options
    },{
        path: '/investors',
        component: Global,
        options
    },{
        path: '/media',
        component: Global,
        options
    },{
        path: '/news',
        component: Global,
        options
    },{
        path: '/careers',
        component: Global,
        options
    },{
        path: '/contact',
        component: Global,
        options
    },
    {
        path: '/admin',
        component: AdminLogin,
        options
    },
    {
        path: '/admin-home',
        component: Admin,
        options
    },
    {
        path: '(.*)',
        component: NotFoundPage,
        options
    },
];

export default routes;