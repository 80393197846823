import React, { useState, useEffect } from 'react';
import {Block,Page,Navbar,NavRight,BlockTitle,Button,Icon,Link,f7,Popup,Card, CardHeader,CardContent, CardFooter,Row,Col, BlockFooter, Chip} from 'framework7-react'

import TextBlock from './text'
import RichTextBlock from './richText'
import ImageBlock from './image'
import { camelize,htmlStringPreview } from '../../../js/util';
import { nanoid } from 'nanoid'
import {DEFAULT_IMAGE} from '../../../js/schema'
import {getTimeStamp} from '../../../js/firebase'
import Moment from 'react-moment'


export default ({data,schema,onChange}) =>{
  
    const [showForm, setShowForm] = useState(false)    
    const [toEdit, setToEdit] = useState({})
    const [contents, setContents] = useState(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)



    useEffect(()=>window.onresize = ()=> setScreenWidth(window.innerWidth),false)
    
    

    const save = (newContent,oldContent)=>{
        console.log('new',newContent)
        console.log('old',oldContent)

        if(!(newContent.title||oldContent.title)) f7.dialog.alert('Article must have title')
        else{
            const updated = oldContent.id?(contents||data).map(c=>{
                if(c.id == oldContent.id) return {...oldContent,...newContent,lastModified:  new Date()}
                else return c
            }):[...(contents||data),{id:nanoid(),...newContent,published:false,createdOn:  new Date(),lastModified:  new Date()}]     

            console.log('updated',updated)
            setToEdit({})
            setContents(updated)
            onChange(updated)
        }
    }

    return(
        <Block>
            <BlockTitle>{schema.label}</BlockTitle>
            <Block>
               {(contents|| data).map((content,idx)=>{
                const isPublished = !(content.lastModified instanceof Date)
                const remove = ()=>{
                    f7.dialog.confirm(`remove ${content.title}?`,null,()=>{
                        const updated = (contents||data).filter(c=>c.title!=content.title)      
                        console.log('remove',updated)    
                        setContents(updated)      
                        onChange(updated)
                    })
                }

               return <Row><Col width={screenWidth>768?'45':'100'}><Card>
                   <CardHeader
                   className="no-border"
                   valign="bottom"
                    style={{
                        backgroundImage: `url(${content.image||DEFAULT_IMAGE})`,
                        height:'100px'
                    }}
                    >
                   </CardHeader>
                   <CardContent>
                       <BlockTitle className='m-0'>{content.title}</BlockTitle>
                       <Block className="py-5">
                       {htmlStringPreview(content.body,100)}
                       </Block>
                       <Block className="mb-5">
                            <BlockFooter><strong>Author</strong><span className="ml-5">{content.author}</span></BlockFooter>
                           <BlockFooter><strong>Published</strong><Moment className="ml-5" fromNow date={!isPublished? content.createdOn:content.createdOn.toDate()}/></BlockFooter>
                           <BlockFooter><strong>LastModified</strong><Moment className="ml-5" fromNow date={!isPublished? content.lastModified:content.lastModified.toDate()}/></BlockFooter>
                           <BlockFooter><strong>status</strong><Chip className="ml-5" color={isPublished?"green":null} text={isPublished?"published":"draft"}></Chip></BlockFooter>
                        </Block>
                    </CardContent>
                   <CardFooter>
                       <Link iconSize="18px" className="text-blue-500" onClick={()=>{
                           setToEdit(content)
                           setShowForm(true)
                        }} iconF7="pencil_circle_fill">Edit</Link>
                       <Link iconSize="18px" className="text-red-500" onClick={remove} iconF7="multiply_circle_fill" >Delete</Link>
                   </CardFooter>
               </Card></Col></Row>})}
               <Block>
                    <ContentForm content={toEdit} showForm={showForm} setShowForm={setShowForm}  save={save} />
                   <Button onClick={()=>setShowForm(true)}>ADD {schema.label.split(' ')[0]} &nbsp; &nbsp;<Icon f7="plus_square"/></Button>
               </Block>
            </Block>
        </Block>
    )
}


const ContentForm = ({showForm,setShowForm,content,save})=>{
    const [title,setTitle] = useState('')
    const [author,setAuthor] = useState('')
    const [image,setImage] = useState('')
    const [body,setBody] = useState('')    
    const [edited,setEdited] = useState([])

    const close = ()=>{
        setTitle('')
        setImage('')
        setBody('')
        setAuthor('')
        setEdited([])
        setShowForm(false)
    }

    return <Popup opened={showForm} onPopupClose={close} className={`p-3 bg-blue-100`}>
        <Page>
        <Navbar title="New Article">
            <NavRight>
                <Link className="text-red-500" popupClose iconF7="multiply_circle_fill" style={{float:'right'}}></Link>
            </NavRight>
          </Navbar>
        <TextBlock data={(title||content.title)} schema={{label:'title'}} onChange={val=>{
            setTitle(val)
            setEdited([...edited,'title'])
        }}></TextBlock>
        <TextBlock data={(author||content.author)} schema={{label:'author'}} onChange={val=>{
            setAuthor(val)
            setEdited([...edited,'author'])
        }}></TextBlock>
        <ImageBlock data={(image||content.image)} schema={{label:'image'}}  onChange={val=>{
            setImage(val)
            setEdited([...edited,'image'])
        }}></ImageBlock>
        <RichTextBlock data={(body||content.body)} schema={{label:'content'}}  onChange={val=>{
            setBody(val)
            setEdited([...edited,'body'])
        }} ></RichTextBlock>
        <div className="px-10"><Button fill onClick={()=>{
            if(!content.title)save({title,author,image,body},content)
            else{
                const saveable= {}
                edited.forEach(el=>{
                    console.log('edited',el)
                    saveable[el] = eval(el)
                })

                save(saveable,content)
            }
            close()
        }} >{content?'UPDATE':'ADD'}</Button></div>
        </Page>
</Popup>
}

