import React, { useState, useEffect } from 'react';
import { Page, Icon, Link,Popover,List,ListItem,f7} from 'framework7-react';
import {FlexBox, FlexItem} from './flex'
import DashboardComponent from './dashboardComponent'
import Account from '../pages/admin/account'
import CMS from '../pages/admin/templates/cms'
import {auth,db,storage} from '../js/firebase.js'
import {schemas} from '../js/schema'

const AdminDashboard = () => {
  const dashboardTabs = [
    {
      id:"account",
      title:"Account",
      icon:"person_circle_fill",
      dropDown:null
    },
    {
      id:"cms",
      title:"Manage Content",
      icon:"doc_text_fill",
      dropDown: Object.keys(schemas).map(page=>{
        return {
          id:page,
          title:page
        }
      })
    }
  ]
  const [active, setActive] = useState(dashboardTabs[0])
  const [activeContent, setActiveContent] = useState({id:Object.keys(schemas)[0]})

  console.log('active admin tab is',activeContent)
  return(
    <div style={{height:"100%",backgroundColor:"white"}}>
      <Page style={{height:"100%"}}>
        <div style={{display:"flex",height:"100%"}}>
          <div style={{flex:"1",backgroundColor:"white",alignSelf:"stretch"}}>
            <img src="static/logo.JPG" width="200px" style={{marginLeft:"auto",marginRight:"auto"}} alt=""/>
           
            <div style={{marginTop:"80px"}}>
              {dashboardTabs.map(tab=>(
                <a onClick={()=>{
                  console.log('active about tab is , ' ,tab)
                  setActive(tab)
                }}>
                  <div style={active.id==tab.id ? {cursor:"pointer",fontFamily:"Montserat-bold",backgroundColor:"#1275bc",color:"white",borderRadius:"0 20px 20px 0",padding:"5px",fontSize:"13px",textAlign:"left",marginTop:"14px"} : {cursor:"pointer",fontFamily:"Montserat-bold",fontSize:"13px",padding:"7px",color:"#4f4f4f",textAlign:"left",marginTop:"14px"}}>
                    <>
                      <FlexBox flex="1">
                        <Icon f7={tab.icon} style={active.id==tab.id ? {color:"white"} :{color:"#1275bc"}} size="18px"></Icon>
                        <span>
                          {tab.title}
                        </span>
                      </FlexBox>
                      <div >
                        {(tab.dropDown)&&(active.id==tab.id) ?
                        <List>
                            {tab.dropDown.map(page=><ListItem
                            onClick={()=>setActiveContent(page)} link className="text-sm" style={page.id==(activeContent||{}).id ? {backgroundColor:"white",color:'#1275bc'} :{backgroundColor:"#1275bc",color:'white'}}>
                              {/* <Icon f7="pencil"  size="15px"></Icon> */}
                            {page.title}</ListItem>)}
                           </List>
                           : null
                        }
                      </div>
                    </>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div style={{flex:"4",backgroundColor:"#f2f2f2",alignSelf:"stretch"}}>
            <div style={{boxShadow:"#80808096 14px -8px 8px 11px",color:"white",padding:"10px 17px"}}>
              <FlexBox>
                <FlexItem flex="8" style={{textAlign:"left"}}>
                  <h3 style={{color:"#1275bc",fontFamily:"Montserat-bold",fontSize:"19px"}}>{active.title}</h3>
                </FlexItem>
                <FlexItem flex="1" style={{textAlign:"center"}}>
                  <Link target="_blank" href="/">
                    <Icon f7="house_fill" size="23px" style={{color:"#1275bc"}}></Icon>
                  </Link>
                </FlexItem>
                <FlexItem flex="3" className="text-gray-700 text-xs" style={{textAlign:"center"}} ><strong>logedin as <em style={{color:'#1275bc'}}>{(auth.currentUser||{displayName:''}).displayName}</em></strong></FlexItem>
                <FlexItem flex="1"style={{textAlign:"center"}} >
                  <Link popoverOpen=".user-pop-menu"><img src="static/user.jpg" width="35px" alt="" style={{borderRadius:"50%"}}/></Link>
                  <Popover className="user-pop-menu">
                    <List>
                      <ListItem link popoverClose onClick={()=>{
                          auth.signOut()
                          f7.views.main.router.navigate('/admin')
                          f7.popover.get('.user-pop-menu').close()
                      }} popoverClose title="Logout" >
                      <Icon size="20" f7="power_fill" slot="media"/>
                      </ListItem>
                    </List>
                  </Popover>
                </FlexItem>
              </FlexBox>
            </div>
            
            <div style={{overflow:'scroll',height:'100%',padding:'2% 5%'}}>
                   {active.id == 'account'&&<Account />}
                  {(active.id == 'cms')&&((auth.currentUser||{displayName:''}).displayName)&&<CMS  page={(activeContent||{}).id} />}
            </div>
       
          </div>
        </div>

      </Page>
    </div>
  )
};

export default AdminDashboard;