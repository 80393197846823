import React, { useState, useEffect } from 'react';
import {Block,BlockTitle,List,ListInput,Progressbar,f7, Row, Col, Icon} from 'framework7-react' 
import {uploadFile} from '../../../js/util'
import {DEFAULT_IMAGE} from '../../../js/schema'

export default ({schema,data=null,onChange})  => {
    const [photo, setPhoto] = useState(null)
    const [progress,setProgress] = useState(0)

    return(
        <Block>
            <BlockTitle>{schema.label}</BlockTitle>
                
                <Row style={{alignItems:'center'}}>
                    <Col width="40"><img className="rounded" width="200px" src={data ||  DEFAULT_IMAGE}></img></Col>
                    <Col width="10"><Icon f7="arrow_right"></Icon></Col>
                    <Col width="40">
                        {progress>0?
                            <>
                                <h5>uploading.. {progress}%</h5>
                                <Progressbar progress={progress}></Progressbar>
                            </>: 
                            (photo && (photo!=data))?
                                <img width="200px" src={photo|| schema.default}></img>
                                :<List>
                                    <ListInput label={schema.label} type="file" accept="image/*" onChange={e=>{
                                        //upload cover image if any
                                        uploadFile(e.target.files[0],(progress)=> {
                                            console.log('uploading..')
                                            setProgress(progress)

                                        }).then(imageURL=>{
                                            console.log("uploaded",imageURL)
                                            setPhoto(imageURL)
                                            setProgress(0)                     
                                            onChange(imageURL) 
                                        }).catch(err=>{
                                            console.error(err)
                                        })
                                    }}></ListInput>
                                </List>
                            }
                    </Col>
                </Row>
        </Block>
    )
}


         