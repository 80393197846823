import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem, Link,f7, f7ready} from 'framework7-react';
import {FlexBox, FlexItem} from '../components/flex'
import Footer from '../components/footer.jsx'
import Particles from 'react-particles-js';
import particleConfig from '../js/particlesjs-config'
import {camelize} from '../js/util'
import {db} from '../js/firebase'
import {getDefault} from '../js/schema'
import ProductSection from './productSection'

const ServicesPage = (props) => {
  
  const servicesTabs = (()=>{
    let tabs = []
    props.data.servicesSection.forEach(product=>{
      product.categories.forEach(category=>{
        if(!tabs.includes(category)) tabs.push(category)
      })
    })
    // console.log('tabs concat',tabs)
    return tabs
  })()


  const [active, setActive] = useState(servicesTabs[0])
  // console.log('active 0 tab',servicesTabs[0])
  // console.log('active is now',active)



  return(
    <div   className="bg-white" style={{height:"100%",marginTop:'2%',textAlign:"center"}}>
      <h1 id="header1" className="my-5 mt-20 block md:hidden" style={{fontSize:'35px',fontWeight:'bolder'}}>Services</h1>
      <div style={{height:'300px',backgroundImage:`url(${props.data.coverImage})`, backgroundPosition:'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover',position:'relative'}}>
      <Particles params={particleConfig} style={{position:'absolute',top:0,left:0,right:0,bottom:0}}/>
      </div>
      <br/>
          {servicesTabs[0]?<ProductSection page="service" products={props.data.servicesSection} tabs={servicesTabs} active={active} setActive={setActive}/>:null}

    </div>
  )
};

export default ServicesPage;