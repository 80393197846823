import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem, Link} from 'framework7-react';
import {FlexBox, FlexItem} from '../components/flex'
import Footer from '../components/footer.jsx'
import Particles from 'react-particles-js';
import particleConfig from '../js/particlesjs-config'
import {getDefault} from '../js/schema'
import ReactHTMLParser from 'react-html-parser'
import {db} from '../js/firebase'

const ContactPage = (props) => {
  
  return(
    <div className="bg-white" style={{height:"100%",marginTop:'70px',textAlign:"center"}}>
      <h1 id="header1" className="my-5 mt-20 block md:hidden" style={{fontSize:'35px',fontWeight:'bolder'}}>Contact Us</h1>
      <div style={{height:'300px',backgroundImage:`url(${props.data.coverImage})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover',backgroundPosition:'center',position:'relative'}}>
      <Particles params={particleConfig} style={{position:'absolute',top:0,left:0,right:0,bottom:0}}/>
      </div>
      <br/>
      {/* mobile view */}
      <div className="md:hidden block"> 

        <div style={{marginTop:"10%",textAlign:"left"}}>
            <div style={{marginBottom:"25px"}}>
            {ReactHTMLParser(props.data.contactInfo)} 
            </div>
        </div>
      </div>
      
      {/* desktop view */}
      <div className="md:block hidden" style={{marginTop:"20px"}}>
            <div style={{margin:'auto',maxWidth:'400px', marginTop:"8px",textAlign:"left"}}>
                  <div style={{marginBottom:"25px"}}>
                   {ReactHTMLParser(props.data.contactInfo)}
                  </div>
              </div>
      </div>
      
    </div>
  )
};

export default ContactPage;