import React, { useState, useEffect } from 'react';
import { Page, Navbar, Block } from 'framework7-react';

const Error = () => {
  useEffect(() => {
  })
  return(
    <Page>
      <Navbar title="Not found" backLink="Back" />
      <Block strong>
        <p>Requested content not found.</p>
      </Block>
    </Page>
  )
};

export default Error;