import {storage} from './firebase'

export const uploadFile =  (file,progressCallback)=>{
    return new Promise((resolve,reject)=>{

        const uploadTask =  storage.ref(location.hostname === "localhost"?'TEST':'CMS').child(`Home/${Date.now()}.${file.type.split('/')[1]}`)
        .put(file, { contentType: file.type })
        
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed', // or 'state_changed'
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded     
            progressCallback(Math.ceil(((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / 10) * 10,snapshot.state)
        }, 
        (error) =>   {   
            reject(error)
        }, 
        async () => {
            // Upload completed successfully, now we can get the download URL
            resolve(await uploadTask.snapshot.ref.getDownloadURL())
            
        })
    })
}


export const camelize = (str="") => str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '')


export const htmlStringPreview = (html, qty)=>{
    
    String.prototype.dotdot = String.prototype.dotdot ||
    function (n=40){
        if(this.split('').length > n) return this.slice(0,n)+"..."
        return this.slice(0,n);
    }

  return html.replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;"," ").dotdot(qty)
};
