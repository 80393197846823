import React, { useState, useEffect } from 'react';
import { Page, Button,Block, Row, Col, BlockTitle, Link, Chip,Icon} from 'framework7-react';
import {FlexBox, FlexItem} from '../components/flex'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Footer from '../components/footer.jsx'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Particles from 'react-particles-js';
import particleConfig from '../js/particlesjs-config'
import ReactHtmlParser from 'react-html-parser';
import {db} from '../js/firebase'
import {getDefault} from '../js/schema'
import {htmlStringPreview} from '../js/util'

const HomePage = (props) => {

  return(
    <div  noNavbar className="bg-white" style={{height:"100%"}}>
        {/* first section */}
        <div className ="h-80 md:h-1/2" style={{position:'relative',backgroundImage:`url(${props.data.coverImage})`}} id="homebg">
        <Particles params={particleConfig} style={{position:'absolute',top:0,left:0,right:0,bottom:0}}/>
            <div >
            <div className="md:grid md:grid-cols-9 grid grid-cols-6 mt-16 md:mt-10" style={{padding:"0",alignItems:'center'}}>
              <div  className="md:col-span-2 col-span-1"></div>
              <div  className="md:col-span-1 col-span-2 md:hidden">
                {/* <h2 id="header1" style={{textAlign:"right"}}>
                  -  Home
                </h2>
                <br/>
                <h2 id="header1" style={{color:"#333",textAlign:"right"}}>
                  {props.data.slogan}...
                </h2> */}
              </div>
              <div  className="md:col-span-1 col-span-0"></div>
              <div  className="md:col-start-6 col-span-5 pt-10 hidden md:block" style={{backgroundColor:"#1275bc",padding:"25px 25px 45px",color:"white",fontFamily:"Montserat-bold",borderRadius:"30px 0 0 0",textAlign:"left",borderLeft:'none'}}>
                  {/* news slider */}
                  <Slide>
                    {props.highlights && props.highlights.map(a_news=>(

                      <div className="p-3 ml-10">

                        <Row>
                          {/* <Col width="40"><img src={a_news.image}style={{borderRadius:"30px 0 0 0"}}></img></Col> */}
                          <Col width="100">
                              <h1 style={{letterSpacing:"1.5px",fontSize:"20px"}}>
                              {a_news.title}
                            </h1>
                            <div  className="m-0 p-0" style={{ fontSize:"8pt",letterSpacing:"0.9px", fontFamily: 'Montserat'}}>
                              {htmlStringPreview(a_news.body,150)}
                            </div>
                            <div className="mt-2">
                              {a_news.tags.filter((c,i)=>i<5).map(c=><Chip  style={{color:"white"}} text={c}></Chip>)}
                            </div>
                            <div className="mt-3">
                              <Link round style={{color:"white",backgroundColor:"transparent",borderColor:"white",width:"50%",justifyContent:'left'}} onClick={()=>{
                              props.setActiveTab(a_news.linkTo)
                            }}>Read more &nbsp;&nbsp; <Icon f7="arrow_right" size="15px"></Icon></Link> 
                          </div>
                          </Col>
                        </Row>
                        
                      </div>
                    ))}
                  </Slide>
              </div>
            </div>
            </div>
        </div>

        {props.highlights ?<div className="slide-container md:hidden mb-10 " style={{position:'relative',backgroundColor:'#1275bc', borderRadius:'40px 40px 0 0',color:'white',fontFamily:"Montserat-bold", marginTop:'-10%',zIndex:1000}}>
            <Slide indicators={true}>
              { props.highlights.map((news, idx)=>(
                <div className="each-fade px-5">
                  <div className="image-container p-5">
                    {/* <img src={news.image} className="w-full " style={{marginLeft:"auto",marginRight:"auto"}}/> */}
                  </div>
                  <div style={{padding:"0 15px"}} className="md:grid md:grid-cols-6 md:text-center text-left">
                  <div className="md:col-span-2"></div>
                  <div className="md:col-span-2">
                  <h1 style={{letterSpacing:"1.5px",fontSize:"25px"}}>
                      {news.title}
                    </h1>
                    <div  className="m-0 p-0 text-justify" style={{ fontSize:"9px",letterSpacing:"0.9px", fontFamily: 'Montserat'}}>
                      {htmlStringPreview(news.body,150)}
                    </div>
                    <div className="mt-2">
                      {news.tags.filter((c,i)=>i<3).map(c=><Chip  style={{color:"white"}} text={c}></Chip>)}
                    </div>
                    <div className="mt-5">
                      <Link round style={{color:"white",backgroundColor:"transparent",borderColor:"white",width:"50%",justifyContent:'left'}} onClick={()=>{
                      props.setActiveTab(news.linkTo)
                    }}>Read more &nbsp;&nbsp; <Icon f7="arrow_right" size="15px"></Icon></Link> 
                  </div>

                  </div>
                  <div className="md:col-span-2"></div>
                </div>
                </div>
              ))}
            </Slide>
            </div>:null}



        <div className="m-5 md:m-16  md:grid md:grid-cols-10">
          <div className="md:col-span-4">
          <img src={props.data.welcomeImage}   alt=""/>
          </div>
          <div className="md:col-span-6">
            <Block className="mt-0 md:ml-10">
              <h1 className="text-2xl md:text-3xl mt-12 md:mt-0" style={{color: '#1275bc', fontWeight:'bold', textOverflow:'inherit'}} >Welcome to {props.global.companyName}</h1>
              <div className="text-justify mt-2 text-sm text-gray-800 ">{ReactHtmlParser(props.data.welcomeText)}</div>
              <div className="mt-5 "> <Link round style={{fontWeight:'bold',color:"#1275bc",backgroundColor:"transparent",borderColor:"white",width:"50%",justifyContent:'left'}} onClick={()=>{
                              props.setActiveTab('about')
                            }}>Read more &nbsp;&nbsp; <Icon f7="arrow_right" size="15px"></Icon></Link> </div>
            </Block>
        </div>
        </div>

  
        <Block  className="my-16 my-0 px-5 md:px-16 " style={{backgroundColor:"#1275bc",}}>
        <Carousel 
              showArrows={false}
              showStatus={false}
              autoPlay={true}
              stopOnHover={true}
              swipeable={true}
              useKeyboardArrows={true}
              infiniteLoop={true}
              >
                {props.cms.articles && props.cms.articles.reverse().filter((a,i)=>i<3).map(a_news=>(

                  <div style={{padding:"5% 2% 10%",color:"white",fontFamily:"Montserat-bold",textAlign:"left",borderLeft:'none'}}>

                    <div className="md:grid md:grid-cols-10">
                      <div className="md:col-span-4"><img src={a_news.image}></img></div>
                      <div  className="px-5 md:px-16 md:col-span-6">
                          <h1 style={{letterSpacing:"0.7px",fontSize:"32px"}}>
                          {a_news.title}
                        </h1>
                        <div  className=" m-0 my-5 p-0 text-justify" style={{ fontSize:"12px",letterSpacing:"0.9px", fontFamily: 'Montserat'}}>
                          {htmlStringPreview(a_news.body,500)}
                        </div>
                        <div className="mt-5">
                        <Link round style={{color:"white",backgroundColor:"transparent",borderColor:"white",width:"50%",justifyContent:'left'}} onClick={()=>{
                              props.setNews(a_news)
                              props.setActiveTab('newsdetail')
                            }}>Read more &nbsp;&nbsp; <Icon f7="arrow_right" size="15px"></Icon></Link> 
                      </div>
                      </div>
                    </div>
                    
                  </div>
                ))}
              </Carousel>
        </Block>
    </div>
  )
};

export default HomePage;