import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem, Link,f7, f7ready} from 'framework7-react';
import {FlexBox, FlexItem} from '../components/flex'
import Footer from '../components/footer.jsx'
import Particles from 'react-particles-js';
import particleConfig from '../js/particlesjs-config'
import {camelize} from '../js/util'
import {db} from '../js/firebase'
import {getDefault} from '../js/schema'
import RichTextSection from './richTextSection'

const InvestorsPage = (props) => {
  const investorsTabs = props.data.investorsSection
  const [active, setActive] = useState(investorsTabs[0])
  // console.log('active 0 tab',investorsTabs[0])
  // console.log('active is now',active)


  return(
    <div   className="bg-white" style={{height:"100%",marginTop:'2%',textAlign:"center"}}>
      <h1 id="header1" className="my-5 mt-20 block md:hidden" style={{fontSize:'35px',fontWeight:'bolder'}}>Investors</h1>
      <div style={{height:'300px',backgroundImage:`url(${props.data.coverImage})`, backgroundRepeat: 'no-repeat',backgroundPosition:'center',backgroundSize: 'cover',position:'relative'}}>
      <Particles params={particleConfig} style={{position:'absolute',top:0,left:0,right:0,bottom:0}}/>
      </div>
      <br/>
          {investorsTabs[0]?<RichTextSection tabs={investorsTabs} page="investors"/>:null}
    </div>
  )
};

export default InvestorsPage;