import React, { useState, useEffect} from 'react';
import {
  App,
  View,
  Link,
  Panel,
  Block,
  Row,
  Col,
  Page,
  Icon
} from 'framework7-react';
import {f7, f7ready} from 'framework7-react';


const MyPanel = (props) => {
  const data = props.global
    let activeTab = props.active
    let setActive = props.setActive
    console.log('active tab is , ',activeTab)
  function PanelLink(e) {
    console.log(e)
    switch (e) {
      case "home":
        
        break;
      case "about":
        f7.panel.close("#mobile_panel",true)
        break;
      default:
        break;
    }
  }

  const links = [
    {
      id: 'Home',
      title:'Home',
      activeName:'home',
      icon: 'house'
    },
    {
      id: 'About',
      title:'About Us',
      activeName:'about',
      icon: 'question'
    },
    {
      id:'Product',
      title:'Products',
      activeName:'product',
      icon: 'cart'

    },
    {
      id:'Services',
      title:'Services',
      activeName:'services',
      icon: 'gear_fill'

    },
    {
      id:'Investors',
      title:'Investors',
      activeName:'investors',
      icon: 'money_dollar_circle'
    },
    // {
    //   id:'Media',
    //   title:'Media',
    //   activeName:'media',
    //   icon: 'square_list'
    // },
    {
      id:'News',
      title:'News',
      activeName:'news',
      icon: 'doc_plaintext'
    },
    {
      id:'Careers',
      title:'Careers',
      activeName:'careers',
      icon: 'briefcase'
    },
    {
      id:'Contact',
      title:'Contact',
      activeName:'contact',
      icon: 'phone'
    }
  ]

  useEffect(()=>{
    console.log('panel effected',activeTab)
    setBg(activeTab)
  })
  const [bg, setBg] = useState(activeTab)
  console.log('bg is , ',bg)
  return (<>

     {/* default navbar */}
      <Panel id="mobile_panel" closeByBackdropClick	 left cover style={{boxShadow:"4px -4px 10px 3px #80808061"}}>
          <Page className="" colorTheme="black" className="bg-white" >
            <div style={{paddingLeft:"0",marginleft:"0"}}>
              <Row className="mx-5 my-2" style={{alignItems:'center'}}>
                <Col width="80">
                  <img src={data.logo}style={{marginLeft:"auto",marginRight:"auto"}} className="w-32" alt="NosDoc Pharma"/>
                </Col>
                <Col width="20">
                  <Link panelClose>
                    <Icon f7="multiply" style={{color:"#1275bc"}}></Icon>
                  </Link>
                </Col>
              </Row>
              <hr/>
              <Block style={{padding:0,marginTop:0,marginRight:'5%'}}>
              {links.map((link,idx)=>(
                  <>
                        <Link iconF7={link.icon} className="text-xs my-3" iconSize="18px" style={bg==link.activeName?{color:"white",backgroundColor:"#1275bc",width:"100%",justifyContent:"end",padding:"8px",borderRadius:"0 15px 15px 0", fontWeight:"bolder"}:{color:"#1275bc",paddingLeft:"8px",fontWeight:"bolder"}} tabLink={`#${link.id}`} onClick={()=>{
                            activeTab = link.activeName;
                            setActive(link.activeName)
                            f7.panel.close("#mobile_panel",true)
                            setBg(link.activeName)
                        }} tabLinkActive={activeTab == link.activeName} id="list_item">
                            &nbsp;&nbsp;&nbsp;{link.title}
                        </Link>
                        <br/>
                  </>
              ))}
              </Block>
              
          </div>
          </Page>
      </Panel>
    </>)

 
}

export default MyPanel;