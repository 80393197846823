import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem, Link} from 'framework7-react';
import AdminLogin from '../../components/adminLogin.jsx'
import AdminDashboard from '../../components/adminDashboard.jsx'

const AdminIndex = () => {
  return(
    <>
      <AdminDashboard />
    </>
  )
};

export default AdminIndex;