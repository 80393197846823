import fb from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";
import 'firebase/storage';
import "firebase/functions"


// Initialize Firebase
var config = {
    apiKey: "AIzaSyCCDAAu7Ps_PLvKuvK4OAsY8VBKvBDjMWE",
    authDomain: "nosdoc-482c0.firebaseapp.com",
    projectId: "nosdoc-482c0",
    storageBucket: "nosdoc-482c0.appspot.com",
    messagingSenderId: "105234470932",
    appId: "1:105234470932:web:b472106b90c287d98a6f01",
    measurementId: "G-CG77SB41FN"
  };

var firebase;

try {
    firebase = fb.initializeApp(config);
    // firebase.analytics()


} catch (err) {
    // we skip the "already exists" message which is
    // not an actual error when we're hot-reloading
    if (!/already exists/.test(err.message)) {
        console.error('Firebase initialization error', err.stack)
    }
}

export const auth = firebase.auth()
export const db = firebase.firestore()
export const functions = firebase.functions()
export const storage = firebase.storage()

if (location.hostname === "localhost") {
    console.warn('using local firebase')
    auth.useEmulator('http://localhost:9099/');
    db.settings({
        host: 'localhost:8088',
        ssl: false
    })
}




export const getTimeStamp = ()=> fb.firestore.FieldValue.serverTimestamp()

export default firebase