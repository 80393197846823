import React, { useState, useEffect} from 'react';
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import {
  App,
  View,
} from 'framework7-react';
import routes from '../js/routes';
import {f7, f7ready} from 'framework7-react';
// assuming mySelector is a reselect selector defined somewhere


const AppComp = () => {
  let app = null;

  useEffect(()=>{
    console.log('use effect')
    f7ready((f7) => {

      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here
      app= f7
    });
  },[])


 

  const params = {
    f7params: {
    id: 'io.framework7.nosdoc', // App bundle ID
    name: 'NOSDOC', // App name
    theme: 'ios', // Automatic theme detection
    
    // App routes
    routes: routes
    }
  }
  return (
      <App params={ params.f7params} className="">
        <View  
          history
          pushState
          main url='/'/>

      </App>
    )

 
}

export default AppComp;