
/**
 * TYPES
 * - text
 * - image
 * - richText
 * - productList
 */

import { camelize } from "./util"

export const DEFAULT_IMAGE = "https://firebasestorage.googleapis.com/v0/b/nosdoc-482c0.appspot.com/o/placeholder.png?alt=media&token=9dfa7b35-ab9a-4d2a-b99e-c2f9a587362a"

export const schemas = {
   Global: [
            {
                label:'logo',
                type:'image',
                default:null
            },
            {
                label:'company name',
                type:'text',
                default:''
            }
        ],
    Home:[
            {
                label:'slogan',
                type:'text',
                default:''
            },
            {
                label:'cover image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'welcome image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'welcome text',
                type:'richText',
                default:'<div><div>'
            }
        ],
        
    About: [
            {
                label:'cover image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'About Section',
                type:'section_richText',
                default:[]
            }
        ],

    Investors: [
            {
                label:'cover image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'investors section',
                type:'section_richText',
                default:[]
            }
        ],

    // Media: [
    //         {
    //             label:'cover image',
    //             type:'image',
    //             default:DEFAULT_IMAGE
    //         },
    //         {
    //             label:'media section',
    //             type:'section_richText',
    //             default:[]
    //         }
    //     ],

    Products: [
            {
                label:'cover image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'products section',
                type:'section_ProductList',
                default:[]
            }
        ],
    Services: [
            {
                label:'cover image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'services section',
                type:'section_ProductList',
                default:[]
            }
        ],
    News: [
            {
                label:'cover image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'article section',
                type:'article',
                default:[]
            }
        ],
    Careers: [
        {
            label:'cover image',
            type:'image',
            default:DEFAULT_IMAGE
        },
        {
            label:'career section',
            type:'section_richText',
            default:[]
        }
        ],
    Contact: [
            {
                label:'cover image',
                type:'image',
                default:DEFAULT_IMAGE
            },
            {
                label:'contact info',
                type:'richText',
                default:""
            }
        ]

}

export const getDefault = page =>{

    const pageContent = schemas[page]
    const defaultData = {}
    pageContent.forEach(content => {
        defaultData[camelize(content.label)] = content.default
    });

    return defaultData
}