import React, { useState, useEffect } from 'react';
import {FlexBox, FlexItem} from '../components/flex'
import {Link} from 'framework7-react';
import {db} from '../js/firebase'
import {getDefault} from '../js/schema'

const Footer = (props) => {

  return(
      <div style={{marginTop:"40px",backgroundColor:"#1275bc",textAlign:"center",justifyContent:"center",padding:"10px",color:"white"}}>
       
          <h2 style={{fontSize:"14px",color:"white",marginBottom:'50px',marginTop:'10px'}}>
          Copyright  <Link href='/admin' target="_blank">&copy;</Link>  {(new Date()).getFullYear()} {props.data.companyName}
          </h2>
          <div className="md:grid md:grid-cols-6 mb-10">
            <div style={{textAlign:"center"}} className="md:col-span-3">
              <Link style={{color:"white"}}>
                <h2 style={{fontSize:"14px",color:"white"}}>
                  Privacy
                </h2>
              </Link>
            </div>
            <div style={{textAlign:"center"}} className="md:col-span-3">
              <Link style={{color:"white"}}>
                <h2 style={{fontSize:"14px",color:"white"}}>
                  Terms & Conditions
                </h2>
              </Link>
            </div>
          </div>
      </div>
  )
};

export default Footer;