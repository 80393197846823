import React, { useState, useEffect } from 'react';
import { Page,BlockTitle, Block,List, ListInput, Button, f7, Link ,ListItem,Card, CardHeader, CardContent,CardFooter, Icon} from 'framework7-react';
import {auth} from '../../js/firebase.js'
import axios from 'axios'
import {CopyToClipboard} from 'react-copy-to-clipboard'


const Home = () => {
    const [displayName , setDisplayName] = useState((auth.currentUser||{displayName:''}).displayName)
    const [isAdmin, setIsAdmin] = useState(false)
    const [newAdmin, setNewAdmin] = useState('')
    const [admins, setAdmins] = useState([])
    const [pendingAdmins, setPendingAdmins] = useState([])
    const [clipboard,setClipboard] = useState('')

    const fetchAdmin = ()=>{
         //get claims
        if(auth.currentUser){
            auth.currentUser.getIdTokenResult(true).then(result=>{
                console.log('token', )
                setIsAdmin(Boolean(result.claims.superAdmin || auth.currentUser.email=='omonuitanyi@gmail.com'))
                axios.get(location.origin=="http://localhost:8080"?`/api/getAllAdmins`:"https://nosdoc-api.vercel.app/getAllAdmins",{
                    headers:{
                        Authorization:auth.currentUser.uid
                    }
                }).
                then(res=>{
                    console.log(res)

                   const admins =  res.data.data.filter(admin=>admin.email!=auth.currentUser.email)
                   setAdmins(admins.filter(admin=>admin.name))
                   setPendingAdmins(admins.filter(admin=>!admin.name))

                }).catch(function(error) {
                    console.log('fetch admins failed',error)
                })
        })
        }else f7.views.main.router.navigate('/admin')
    }

    useEffect(()=>{
        if(auth.currentUser == null) f7.views.main.router.navigate('/admin')
        // fetchAdmin()
    },false)

    const save = (e)=>{
        e.preventDefault()
        f7.dialog.preloader('saving changes...')

        auth.setC
       
        auth.currentUser.updateProfile({
            displayName
            // photoURL: "https://example.com/jane-q-user/profile.jpg"
          }).then(function() {
            f7.dialog.alert('saved successfuly!')
        }).catch(function(error) {
            console.error(error)
            f7.dialog.alert('Failed to save changes')
        }).finally(()=>f7.dialog.close());
    }


    const invite = (e)=>{

        e.preventDefault()
        f7.dialog.preloader('getting invite link...')
        axios.post(location.origin=="http://localhost:8080"?`/api/createAdmin`:"https://nosdoc-api.vercel.app/createAdmin",{email:newAdmin},{
            headers:{
                Authorization:auth.currentUser.uid
            }
        }).
        then(res=>{
            console.log(res)
            fetchAdmin()
            setNewAdmin('')
        }).catch(function(error) {
            console.log('invite failed',error)
            f7.dialog.alert('Failed to invite')
        }).finally(()=>f7.dialog.close());
    }


    const deleteAccount = (admin)=>{
        f7.dialog.confirm(`Delete ${admin.name||""} (${admin.email})?`,()=>{
            f7.dialog.preloader('deleting...')
            axios.post(location.origin=="http://localhost:8080"?`/api/deleteAdmin`:"https://nosdoc-api.vercel.app/createAdmin",{adminToDelete:admin.id},{
                headers:{
                    Authorization:auth.currentUser.uid
                }
            }).
            then(res=>{
                console.log(res)
                fetchAdmin()
            }).catch(function(error) {
                console.log('invite failed',error)
                f7.dialog.alert('Failed to invite')
            }).finally(()=>f7.dialog.close());})
     
    }



  return(
    <div style={{paddingTop:"0"}}>

       {!displayName?<Block className="bg-red-300">
            Please Edit your name to make any changes <Icon color="white" size="20" f7="exclamationmark_circle_fill"></Icon>
        </Block>:null}

       <Block>
            <List><ListInput readOnly value={(auth.currentUser||{email:''}).email}></ListInput></List>
        </Block>

      <Card className="mt-10">
          <CardHeader> <BlockTitle>Account</BlockTitle></CardHeader>
          <CardContent className="m-5  my-10">
                <form onSubmit={save}>
                    <Block>
                        <List>
                            <ListInput floatingLabel outline required label="full name" type="text" value={displayName} onChange={(e)=>setDisplayName(e.target.value)}></ListInput>
                        </List>
                    </Block>
                    <Block className="mx-48">
                        <Button type="submit" fill >Save Changes</Button>
                    </Block>
                </form>
          </CardContent>
          <CardFooter></CardFooter>
      </Card>

      <Card className={`${isAdmin?'block':'hidden'} mt-10`}>
          <CardHeader><BlockTitle>Admins</BlockTitle></CardHeader>
          <CardContent className="m-5  my-10">
                <Block >
                            
             {admins.filter(admin=>admin.name).map(admin=><List inset>
                <ListItem>{admin.name} | {admin.lastLogin} <Link style={{color:'crimson',float:'right'}} iconF7="clear_fill" onClick={()=>deleteAccount(admin)}></Link></ListItem>
                
            </List>)
             }
            {(admins.length==0)?"No Admins":"" }
        </Block>
          </CardContent>
          <CardFooter></CardFooter>
      </Card>

      
      <Card className={`${isAdmin?'block':'hidden'} mt-10`}>
          <CardHeader> <BlockTitle>Pending Admins</BlockTitle></CardHeader>
          <CardContent className="m-5  my-10"><List inset>
          {pendingAdmins.map(admin=>
                <ListItem className='my-5'>
                    {admin.email}  
                <CopyToClipboard text={clipboard} onCopy={() =>f7.dialog.alert(`invite link copied to clipboad`,'Copied')}>
                        {/* <span style={{display:'hidden'}} className="hidden">{admin.inviteLink}</span> */}
                    <Link style={{color:'crimson'}} iconF7="doc_on_clipboard" onClick={()=>setClipboard(admin.inviteLink)}>COPY INVITE LINK</Link> 
                </CopyToClipboard>
                    
                    <Link style={{color:'crimson',float:'right'}} iconF7="clear_fill" onClick={()=>deleteAccount(admin)}></Link>
                </ListItem>
                
            )}</List>
            {(pendingAdmins.length==0)?"No Pending invitations":"" }
          </CardContent>
          <CardFooter></CardFooter>
      </Card>


      <Card className={`${isAdmin?'block':'hidden'} mt-10`}>
          <CardHeader> <BlockTitle>Invite New Admins</BlockTitle></CardHeader>
          <CardContent className="m-5  my-10">
          <form onSubmit={invite}>
            <Block className={`${isAdmin?'block':'hidden'}`}>
                <BlockTitle>Invite admin</BlockTitle>
                <List inset>
                    <ListInput floatingLabel value={newAdmin} required outline label="Invite email" type="email" onChange={(e)=>setNewAdmin(e.target.value)}></ListInput>
                </List>
                <Block className="mx-48">
                    <Button type="submit" fill >Save Changes</Button>
                </Block>
            </Block>
        </form>
          </CardContent>
          <CardFooter></CardFooter>
      </Card>
        

  </div>
  )
};

export default Home;