
import React, { useState, useEffect } from 'react';
import {Block,BlockTitle,TextEditor} from 'framework7-react' 

export default ({schema,data="",onChange})  => {
    const [value,setValue] = useState(null)
    return(
        <Block>
            <BlockTitle>{schema.label}</BlockTitle>
            <TextEditor
                    resizable
                    value={(value||data)}
                    placeholder={`Enter ${schema.label}...`}
                    buttons={[
                        ['bold', 'italic', 'underline', 'strikeThrough'],
                        ['h1', 'h2', 'h3'] ,
                        ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],                  
                      ]}
                    onTextEditorChange={val=>{
                        setValue(val)
                        onChange(val)
                    }} />
        </Block>
    )
}