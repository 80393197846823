import React, { useState, useEffect } from 'react';
import { Page,BlockTitle, Block,List, ListInput, Button, f7,TextEditor,Progressbar, BlockFooter, Preloader } from 'framework7-react';
import {auth,db,storage} from '../../../js/firebase.js'

import {schemas} from '../../../js/schema'
import { camelize } from '../../../js/util';

//blocks
import TextBlock from './text'
import ImageBlock from './image'
import RichTextBlock from './richText'
import SectionRichText from './section_richText'
import SectionProductList from './section_productList.jsx';
import ArticleBlock from './article.jsx';



let currentPage = 'Global'
const Cms = ({page}) => {

    const [changes,setChanges] = useState({})
    const [data, setData] = useState({})
    const [saving,setSaving] = useState(false)


    console.log(changes)

    const save = async()=>{

        // run only if changes exist
        if(Object.keys(changes).length){

            setSaving(true)
            db.collection('CMS').doc(page).set(changes,{merge:true}).then(res=>{
                console.log('home cms saved success')
                f7.dialog.alert('changes saved successfullly!')
                setChanges({})
                getData()
            }).catch(err=>{
                console.error(err)
                f7.dialog.alert('cms save failed')
            }).finally(()=>{
                setSaving(false)
            })
        }

    }

    const getData = async ()=>{
        db.collection('CMS').doc(page).get().then(doc=>{
            console.log(doc.data())
            if(doc.exists) setData({...data,...doc.data()})
            else setData({})
        })
    }

    useEffect(()=>{
        getData()
    },false)

    console.log('currentPage',currentPage)
    if(page!=currentPage){
        currentPage = page
        getData()
    }

  return(
    <div style={{paddingTop:"0"}}>
        <BlockTitle><h1>MANAGE - {page}</h1></BlockTitle>
        <Block>
            {
                schemas[page].map((block,idx)=>{
                    const onChange = value =>  setChanges({...changes,...{[camelize(block.label)]:value}})
                    const info = data[camelize(block.label)]

                    switch (block.type) {
                        case 'text':
                            return <><TextBlock schema={block} data={info||''} onChange={onChange} ></TextBlock><hr/></>
                        case 'image':
                            return  <><ImageBlock schema={block} data={info} onChange={onChange} ></ImageBlock><hr/></>
                        case 'richText':
                            return  <><RichTextBlock schema={block} data={info||''} onChange={onChange} ></RichTextBlock><hr/></>
                        case 'section_richText':
                            return  <><SectionRichText schema={block} data={info||[]} onChange={onChange} ></SectionRichText><hr/></>
                        case 'section_ProductList':
                            return  <><SectionProductList schema={block} data={info||[]} onChange={onChange} ></SectionProductList><hr/></>
                        case 'article':                            
                            return  <><ArticleBlock schema={block} data={info||[]} onChange={onChange} ></ArticleBlock><hr/></>
                        default:

                            break;
                    }
                })
            }
        </Block>
        <Block className="mx-30">
            {(Object.keys(changes).length)?<Button fill onClick={save}>{saving? <Preloader color="white"/>:"publish changes"}</Button>:<Button fill style={{backgroundColor:'lightgray'}}>Publish changes</Button>}
        </Block>
      
  </div>
  )
};

export default Cms;