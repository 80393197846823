import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem, Link,f7, f7ready} from 'framework7-react';
import {FlexBox, FlexItem} from '../components/flex'
import ReactHTMLParser from 'react-html-parser'
import {camelize} from '../js/util'


export default ({tabs,page})=>{
  
  const [active, setActive] = useState(tabs[0])
  
  
  return(
    <>
     <Popover className={`${page}-menu`}>
        <List>
          {tabs.map(tab=>{
            console.log(tab)
          return(
            <ListItem link="#" style={(camelize((active||tabs[0]).label)==camelize(tab.label) )? {cursor:"pointer",fontFamily:"Montserat-bold",backgroundColor:"#1275bc",color:"white"} : {cursor:"pointer",fontFamily:"Montserat-bold"}} onClick={()=>{
              console.log('active about tab is , ' ,tab)
              setActive(tab)
            }} popoverClose title={tab.label} />
          )})}
        </List>
        <br/>
      </Popover>
    {/* mobile view */}
    <div className="md:hidden block"> 
    <div className="m-3">
          <Button 
          small 
          outline 
          round 
          popoverOpen={`.${page}-menu`}
          style={{fontFamily:"Montserat-bold",fontSize:"14px"}}
          >
            {(active||tabs[0]).label}
            <span style={{marginLeft:"10px"}}>
              <Icon f7="chevron_up" size="15"></Icon>
            </span>
          </Button>
      </div>
      <div style={{marginTop:"25px",fontSize:"16px",textAlign:"center",padding:"0 10px",fontFamily:"Montserat",paddingBottom:"40px"}} id="header2">
      {ReactHTMLParser((active||tabs[0]).body)}
      </div>
    </div>
    
    {/* desktop view */}
    <div className="md:block hidden" style={{marginTop:"20px"}}>
       <FlexBox ai="start">
          <FlexItem flex="1" style={{paddingTop:'5%'}}>
            {tabs.map(tab=>(
              <div style={(camelize((active||tabs[0]).label)==camelize(tab.label)) ? {cursor:"pointer",fontFamily:"Montserat-bold",backgroundColor:"#1275bc",color:"white",borderRadius:"0 20px 20px 0",padding:"5px",fontSize:"13px",textAlign:"left"} : {cursor:"pointer",fontFamily:"Montserat-bold",fontSize:"13px",padding:"5px",color:"#4f4f4f",textAlign:"left"}}>
                  <a onClick={()=>{
                    console.log('active about tab is , ' ,tab)
                    setActive(tab)
                  }}>
                    {tab.label}
                  </a>
              </div>
            ))}
          </FlexItem>
          <FlexItem flex="1"></FlexItem>
          <FlexItem flex="4">
            <div  className="titleTag" style={{marginRight:"auto",width:"50%"}}>{(active||tabs[0]).label}</div>
            <div style={{marginTop:"25px",fontSize:"16px",textAlign:"justify",padding:"0 10px 40px 0",fontFamily:"Montserat"}} id="header2">
            {ReactHTMLParser((active||tabs[0]).body)}
            </div>
          </FlexItem>
          <FlexItem flex="2"></FlexItem>
        </FlexBox>
    </div></>
)}