import React, { useState, useEffect } from 'react';
import { Page, Navbar, Row, Col, Button, NavLeft, NavRight, NavTitle, Link, Tabs, Tab, Toolbar,Popover,List,ListItem, Preloader, Icon, View, Block,f7 } from 'framework7-react';
import HomePage from '../components/homePage.jsx';
import AboutPage from '../components/aboutPage.jsx';
import ProductPage from '../components/productsPage.jsx';
import ServicesPage from '../components/servicesPage.jsx';
import InvestorsPage from '../components/investorsPage.jsx';
import MediaPage from '../components/mediaPage.jsx';
import NewsPage from '../components/newsPage.jsx';
import NewsDetailPage from '../components/newsDetailPage.jsx';
import CareersPage from '../components/careersPage.jsx';
import ContactPage from '../components/contactPage.jsx';
import MyPanel from '../components/panel'
import Footer from '../components/footer'
import {FlexBox, FlexItem} from '../components/flex'
import {db} from '../js/firebase'
import {getDefault} from '../js/schema'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


const Global = () => {
  const [activeTab , setActiveTab] = useState('home')
  const [activeNews , setNews] = useState(null)
  const [data, setData] = useState({})
  const globalData = data.Global || getDefault('Global')
  const [highlights,setHighlights] = useState([])
  const [loading, setLoading] = useState(true)
  
  const router = f7.views.main.router



  useEffect(()=>{
    //set routes
    
    const path = router.currentRoute.path.substring(1)
    if(path!=''){
      console.log('current route=> /',path)
      setActiveTab(path)
      router.refreshPage()
    }


    //get global data
      db.collection('CMS').get().then(snap=>{
          const cms = {}
          snap.forEach(doc=> cms[doc.id] =doc.data())
          //set Data
          setData(cms)

          // set Loading
          setLoading(false)

           // set highlights
          const selected = []
          for(const [page,data] of Object.entries(cms)){
            for(const [key,value] of Object.entries(data)){
              if(key.includes('Section')){
                if(value.length>0){
                  console.log('highlighted',key,value,'in',page)
                  selected.push({
                    title: value[0].title || value[0].label ||'',
                    body: value[0].body || value[0].description || value[0].content ||'',
                    tags: value[0].tags || value[0].categories ||[],
                    linkTo: page.toLowerCase() || 'home'
                  })
                }
              }
            }
          }
          setHighlights(selected.sort(function(a, b){  
            const sortingArr = ['about','services','products','news','investors','careers','contact']
            return sortingArr.indexOf(a.linkTo.toLowerCase()) - sortingArr.indexOf(b.linkTo.toLowerCase());
          }))
      })



  },[false])


  return(
    <Page className={`bg-white`} style={{paddingTop:"0"}}>
        <div style={{textAlign:"center",marginLeft:"auto",marginRight:"auto",marginTop:"200px",display:loading?'block':'none'}}>
          <img src='static/logo.png' className="w-40 md:w-40" style={{marginLeft:"auto",marginRight:"auto"}}  alt="Nosdoc Pharma"/>
          <br/>
          <Loader
            type="MutatingDots"
            color="#9a2415"
            secondaryColor="#0d3187"
          />
        </div>
      <div style={{display:loading?'none':'block'}}>
      <MyPanel global={globalData} active={activeTab} setActive={setActiveTab}/>
      {/* mobile view */}
      <Navbar className="items-center bg-yellow-50 p-3 mb-5 border-b-2 border-gray-100 block md:hidden m-0">
        <NavLeft>
          <Link iconOnly iconF7="line_horizontal_3" iconSize="28px" style={{color:"#1275bc"}} panelOpen="#mobile_panel"></Link>
        </NavLeft>
        <NavTitle>
          <Link href="/" onClick={()=>setActiveTab('home')}>
            <img src={globalData.logo} className="w-40 md:w-32" alt="Nosdoc Pharma"/>
          </Link>
        </NavTitle>
      </Navbar>

      <Popover className="user-menu">
        <List>
          <ListItem link="/dashboard"  popoverClose title="training" > <Icon slot="media" size="15" f7="house_fill"></Icon></ListItem>
        </List>
      </Popover>

      {/* desktop view */}
      <Navbar className="items-center bg-yellow-50 p-3 mb-5 hidden md:block m-0">
        <FlexBox className="w-full">
          <FlexItem flex="1.5">
            <Link href="/">
              <img src={globalData.logo}className="" style={{maxHeight:'55px',marginLeft:'10%',width:"80%"}} alt=""/>
            </Link>
          </FlexItem>          
          <FlexItem flex="8" className="ml-10">
            <Toolbar tabbar id="homeTab" top noHairline style={{fontSize:"12px",position:"sticky", transform:"inherit"}}>
              <Link tabLink="#Home" onClick={()=>setActiveTab('home')} tabLinkActive={activeTab == 'home'}>
                Home
              </Link>
              <Link tabLink="#About" onClick={()=>setActiveTab('about')} tabLinkActive={activeTab == 'about'}>
                About us
              </Link>
              <Link tabLink="#Product" onClick={()=>setActiveTab('products')} tabLinkActive={activeTab == 'products'}>
                Products
              </Link>
              <Link tabLink="#Services" onClick={()=>setActiveTab('services')} tabLinkActive={activeTab == 'services'}>
               Services
              </Link>
              <Link tabLink="#Investors" onClick={()=>setActiveTab('investors')} tabLinkActive={activeTab == 'investors'}>
                Investors
              </Link>
              {/* <Link tabLink="#Media" onClick={()=>setActiveTab('media')}tabLinkActive={activeTab=='media'}>
                Media
              </Link> */}
              <Link tabLink="#News" onClick={()=>setActiveTab('news')} tabLinkActive={activeTab=='news'||activeTab == 'newsdetail'}>
                News
              </Link>
              <Link tabLink="#Careers" onClick={()=>setActiveTab('careers')} tabLinkActive={activeTab == 'careers'}>
                Careers
              </Link>
              <Link tabLink="#Contact" onClick={()=>setActiveTab('contact')} tabLinkActive={activeTab == 'contact'}>
                Contact us
              </Link>
            </Toolbar>
          </FlexItem>
        </FlexBox>
      </Navbar>


      <Block className="m-0 p-0">
      <Tabs style={{height:"100%"}}>
        <Tab id="Home" className="mt-0" style={{height:"100%"}} tabActive={activeTab == 'home'} >
          <HomePage cms={data} global={globalData} data={data.Home || getDefault('About')} highlights={highlights} setActiveTab={setActiveTab} setNews={setNews}/>
        </Tab>
        <Tab id="About" className="page-content mt-0" tabActive={activeTab == 'about'} style={{padding:"1px"}}>
          {activeTab == 'about'?<AboutPage data={data.About || getDefault('About')} setActiveTab={setActiveTab}/>:null}
        </Tab>
        <Tab id="Product" className="page-content mt-0" tabActive={activeTab == 'products'} style={{padding:"1px"}}>
          <ProductPage data={data.Products || getDefault('Products')} setActiveTab={setActiveTab}/>
        </Tab>
        <Tab id="Services" className="page-content mt-0" tabActive={activeTab == 'services'} style={{padding:"1px"}}>
          <ServicesPage data={data.Services || getDefault('Services')}  setActiveTab={setActiveTab}/>
        </Tab>
        <Tab id="Investors" className="page-content mt-0" tabActive={activeTab == 'investors'} style={{padding:"1px"}}>
          <InvestorsPage data={data.Investors || getDefault('Investors')} setActiveTab={setActiveTab}/>
        </Tab>
        {/* <Tab id="Media" className="page-content mt-0" tabActive={activeTab == 'media'} style={{padding:"1px"}}>
          <MediaPage setActiveTab={setActiveTab} />
        </Tab> */}
        <Tab id="News" className="page-content mt-0" tabActive={activeTab == 'news'} style={{padding:"1px"}}>
          <NewsPage data={data.News || getDefault('News')} setActiveTab={setActiveTab} setActiveNews={setNews}/>
        </Tab>
        <Tab  className="page-content mt-0" tabActive={activeTab == 'newsdetail'} style={{padding:"1px"}}>
          <NewsDetailPage news={activeNews} setActiveTab={setActiveTab}/>
        </Tab>
        <Tab id="Careers" className="page-content mt-0" tabActive={activeTab == 'careers'} style={{padding:"1px"}}>
          <CareersPage data={data.Careers || getDefault('Careers')} setActiveTab={setActiveTab}/>
        </Tab>
        <Tab id="Contact" className="page-content mt-0" tabActive={activeTab == 'contact'} style={{padding:"1px"}}>
          <ContactPage data={data.Contact || getDefault('Contact')} setActiveTab={setActiveTab}/>
        </Tab>
      </Tabs>
      </Block>
      <Footer data={globalData}/>
      </div>     
  </Page>
  )
};

export default Global;