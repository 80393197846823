import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem,Block,BlockFooter, Link} from 'framework7-react';
import {FlexBox, FlexItem} from '../components/flex'
import Footer from '../components/footer.jsx'
import ReactHtmlParser from 'react-html-parser'
import Moment from 'react-moment'

const NewsDetailPage = (props) => {

    const article = props.news

  return article?(
    <div className="bg-white" style={{height:"100%",marginTop:'70px'}}>      
    <Block className="mt-10"><Link style={{color:'#1275bc'}} color="#1275bc" onClick={()=>props.setActiveTab('news')} iconF7="lessthan_circle_fill">back</Link> </Block>
    <h1 id="header1" className="my-5 mt-20 text-center" style={{fontSize:'35px',fontWeight:'bolder'}}>{article.title}</h1>
    <div style={{height:'300px',backgroundImage:`url("${article.image}")`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}></div>
    <Block className='my-5 text-left'>
            <BlockFooter><h6  className='text-gray-700'><strong>Author</strong> <strong style={{color:'#1275bc'}}  className='text-gray-500 text-md'>{article.author}</strong></h6> </BlockFooter>
            <BlockFooter><strong className='text-gray-400 text-xs'> <>Published <Moment  fromNow date={article.createdOn.toDate()}></Moment></></strong> </BlockFooter>
        </Block>
      <br/>
        <Block className="grid md:grid-cols-6 text-gray-900 mx-10 my-5 md:m-15  text-md mb-50" style={{fontFamily:'montserat'}}>
        <div className="md:col-start-2 md:col-span-4">{ReactHtmlParser(article.body)}</div>
        </Block>
    </div>
  ): <></>
};

export default NewsDetailPage;