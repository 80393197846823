import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Popover, List, ListItem, Link,f7, f7ready,Row, Col,Block} from 'framework7-react';
import Footer from '../components/footer.jsx'
import Particles from 'react-particles-js';
import particleConfig from '../js/particlesjs-config'
import {db} from '../js/firebase'
import {getDefault} from '../js/schema'
import ArticleCard from './articleCard'


const NewsPage = (props) => {

  const articles = props.data.articleSection||[]
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  useEffect(()=>window.onresize = ()=> setScreenWidth(window.innerWidth),false)


  return(
    <div   className="bg-white" style={{height:"100%",marginTop:'2%',textAlign:"center"}}>
      <h1 id="header1" className="my-5 mt-20 block md:hidden" style={{fontSize:'35px',fontWeight:'bolder'}}>News</h1>
      <div style={{height:'300px',backgroundImage:`url(${props.data.coverImage})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover',backgroundPosition:'center',position:'relative'}}>
      <Particles params={particleConfig} style={{position:'absolute',top:0,left:0,right:0,bottom:0}}/>
      </div>
      <br/>

        <Block className="m-10">
            <Row>
            {articles.map(article=><Col width={screenWidth>768?'45':'100'}>
                <ArticleCard article={article} more={news=>{
                    props.setActiveNews(news)
                    props.setActiveTab('newsdetail')
                }}></ArticleCard>
            </Col>)}
            </Row>
        </Block>      
    </div>
  )
};

export default NewsPage;