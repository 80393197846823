
import React, { useState, useEffect } from 'react';
import { Card,CardContent,CardHeader,CardFooter, Block, BlockFooter, Link, BlockTitle} from 'framework7-react';
import {htmlStringPreview} from '../js/util'
import Moment from 'react-moment'

const ArticleCard = ({article,more})=>{

    return <Card style={{margin:"10px 0 25px 0"}}>
    <CardHeader style={{padding:0}}>
      <img src={article.image} className="w-full"></img>
    </CardHeader>
    <CardContent className="py-5">
        <Block>
        <BlockTitle id="header1">{article.title}</BlockTitle>
        <div className='text-xs'>{htmlStringPreview(article.body,100)}</div>
        </Block>
    </CardContent>
    <CardFooter>
        <Block className='my-5 text-left'>
            <BlockFooter><strong>Published</strong> <Moment fromNow date={article.createdOn.toDate()}></Moment></BlockFooter>
            <BlockFooter><strong>Author</strong> <span>{article.author}</span></BlockFooter>
        </Block>
       <Link className="mt-5" style={{color:"#1275bc"}} onClick={()=>{
      more(article)
    }}>read more</Link>
    </CardFooter>
   
  </Card>
}


export default ArticleCard